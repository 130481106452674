export const navItems = [
  {
    title: "Главная",
    url: "main",
  },
  {
    title: "Каталог",
    url: "catalog",
  },
  {
    title: "О нас",
    url: "about",
  },
  {
    title: "Контакты",
    url: "contacts",
  },
];

import userWhite from "../../assets/icons/user_white.png";
import userOrange from "../../assets/icons/user_orange.png";
import basketWhite from "../../assets/icons/basket_white.png";
import basketOrange from "../../assets/icons/basket_orange.png";

export const icons = {
  userWhite,
  userOrange,
  basketWhite,
  basketOrange,
};

import "./Cover.css";
import coverImg from "../../assets/images/cover.png";
import { Title } from "../ui/Title/Title";

export const Cover = (): JSX.Element => {
  return (
    <section
      className="cover"
      style={{ backgroundImage: `url(${coverImg})` }}
    >
      <Title size="h1" text="miyukon" />
    </section>
  );
};

import "./Page404.css"

export const Page404 = ():JSX.Element => {
  return (
    <section className="page-404">
      <div className="page-404-wrap">
        <p className="page-404-white">404</p>
        <p className="page-404-orange">404</p>
      </div>
    </section>
  )
}
import "./Title.css";
import { TitleProps } from "./types/title.type";

export const Title = ({ size, text }: TitleProps): JSX.Element => {
  return (
    <>
      {size === "h1" && (
        <h1 className="h1">
          <p className="h1-white">{text}</p>
          <p className="h1-orange">{text}</p>
        </h1>
      )}
      {size === "temp" && <h1 className="temp-title">{text}</h1>}
      {size === "h2" && <h2 className="h2">{text}</h2>}
      {size === "h3" && <h3>{text}</h3>}
    </>
  );
};

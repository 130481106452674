import { Header } from "../components/Header/Header";
import { MainWrapper } from "../components/ui/Wrappers/MainWrapper/MainWrapper";
import { Nopage } from "../pages/Nopage";

export const NopageLayout = (): JSX.Element => {
  return (
    <MainWrapper>
      <Header />
      <Nopage />
    </MainWrapper>
  );
};

import { navItems } from "../../services/data/menu.data";
import { NavigationItem } from "../ui/Navigation/NavigationItem/NavigationItem";
import "./Navigation.css";

export const Navigation = (): JSX.Element => {
  return (
    <nav className="navigation">
      {navItems &&
        navItems.map((item) => (
          <NavigationItem key={item.url} title={item.title} url={item.url} />
        ))}
    </nav>
  );
};

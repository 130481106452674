import { TextTemp } from "../components/ui/Text/TextTemp";
import { Title } from "../components/ui/Title/Title";
import { MainWrapper } from "../components/ui/Wrappers/MainWrapper/MainWrapper";

export const TempLayout = (): JSX.Element => {
  return (
    <MainWrapper classStyle="temp">
      <Title size="temp" text="MIYUKON" />
      <TextTemp text="Скоро тут будет наш сайт" />
    </MainWrapper>
  );
};

import { icons } from "../../../services/data/icons.data";
import "./Icon.css";
import { IconProps } from "./types/icon.type";

export const Icon = ({ icon, styles }: IconProps): JSX.Element => {
  return (
    <img
      className={styles ? `icon ${styles}` : "icon"}
      src={icons[icon]}
      alt=""
    />
  );
};

import { Outlet } from "react-router-dom";
import { Header } from "../components/Header/Header";
import { MainWrapper } from "../components/ui/Wrappers/MainWrapper/MainWrapper";

export const BasicLayout = (): JSX.Element => {
  return (
    <MainWrapper>
      <Header />
      <Outlet />
    </MainWrapper>
  );
};

import { Title } from "../ui/Title/Title";
import "./RequisitesInfo.css";

export const RequisitesInfo = (): JSX.Element => {
  return (
    <section className="requisites-info">
      <Title size="h2" text="Реквизиты" />
      <div className="requisites-table">
        <div className="requisites-table-name requisites-info-border">
          Название организации
        </div>
        <div className="requisites-table-info requisites-info-border">
          ИП Кондратов Михаил Юрьевич
        </div>
        <div className="requisites-table-name requisites-info-border">ИНН</div>
        <div className="requisites-table-info requisites-info-border">
          482417150445
        </div>
        <div className="requisites-table-name requisites-info-border">ОГРН</div>
        <div className="requisites-table-info requisites-info-border">
          324480000024896
        </div>
        <div className="requisites-table-name requisites-info-border">
          Расчетный счет
        </div>
        <div className="requisites-table-info requisites-info-border">
          40802810400006239407
        </div>
        <div className="requisites-table-name requisites-info-border">Банк</div>
        <div className="requisites-table-info requisites-info-border">
          АО "ТИНЬКОФФ БАНК"
        </div>
        <div className="requisites-table-name requisites-info-border">
          Юридический адрес банка
        </div>
        <div className="requisites-table-info requisites-info-border">
          Москва, 127287, ул. Хуторская 2-я, д. 38А, стр. 26
        </div>
        <div className="requisites-table-name requisites-info-border">
          Корр.счет банка
        </div>
        <div className="requisites-table-info requisites-info-border">
          30101810145250000974
        </div>
        <div className="requisites-table-name requisites-info-border">
          ИНН банка
        </div>
        <div className="requisites-table-info requisites-info-border">
          7710140679
        </div>
        <div className="requisites-table-name">БИК банка</div>
        <div className="requisites-table-info">044525974</div>
      </div>
    </section>
  );
};

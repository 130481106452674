import { Link } from "react-router-dom";
import "./UserIcon.css";
import { Icon } from "../../ui/Icon/Icon";

export const UserIcon = (): JSX.Element => {
  return (
    <Link to="account" className="user-icon">
      <Icon icon="userWhite" styles="user-icon-white" />
      <Icon icon="userOrange" styles="user-icon-orange" />
    </Link>
  );
};

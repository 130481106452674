import { Link } from "react-router-dom";
import { NavigationItemProps } from "../types/nav.type";
import "./NavigationItem.css";

export const NavigationItem = ({
  title,
  url,
}: NavigationItemProps): JSX.Element => {
  return (
    <Link to={`/${url}`} className="navigation-item">
      <p className="navigation-item-white">{title}</p>
      <p className="navigation-item-orange">{title}</p>
    </Link>
  );
};

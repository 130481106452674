import { Link } from "react-router-dom";
import { Icon } from "../../ui/Icon/Icon";
import "./BasketIcon.css";

export const BasketIcon = (): JSX.Element => {
  return (
    <Link to="basket" className="basket-icon">
      <Icon icon="basketWhite" styles="basket-icon-white" />
      <Icon icon="basketOrange" styles="basket-icon-orange" />
      <p className="basket-count">153</p>
    </Link>
  );
};

import { Link } from "react-router-dom";
import "./Logo.css";
import { LogoProps } from "./types/logo.type";

export const Logo = ({ size = "medium" }: LogoProps): JSX.Element => {
  const fontSelect = () => {
    if (size === "small") return "1.5rem";
    if (size === "big") return "8rem";
    return "2rem";
  };

  return (
    <Link to="/main" className="logo">
      <p className="logo-white" style={{ fontSize: fontSelect() }}>
        MIYUKON
      </p>
      <p className="logo-orange" style={{ fontSize: fontSelect() }}>
        MIYUKON
      </p>
    </Link>
  );
};
